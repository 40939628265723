/* eslint-disable max-len */
import styled from 'styled-components';
import {keyframes, css} from 'styled-components';

const breatheAnimation = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.65 }
  100% { opacity: 1; }
`;

const moveIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(30px);
  }
  to {
    opacity: 1;
    transform: translateX(10px);  
  }
`;

export const Button = styled.button`
  ${props => (props.disabled === true ? 'disabled' : '')};
  border: ${props => (props.border ? props.border : 'none')};
  box-shadow: ${props => (props.boxShadow ? props.boxShadow : 'none')};
  cursor: ${props => (props.cursor ? props.cursor : 'pointer')};
  appearance: none;
  white-space: nowrap;
  pointer-events: ${props => (props.event ? props.event : '')};
  padding: ${props => (props.padding ? props.padding : '1rem 1.25rem')};
  display: ${props => (props.display ? props.display : '')};
  margin: ${props => props.margin && props.margin};
  opacity: ${props => props.opacity && props.opacity};
  max-width: ${props => props.maxWidth && props.maxWidth};
  border-radius: ${props => props.borderRadius && props.borderRadius};
  font-weight: ${props => props.fontWeight ? props.fontWeight : 'bold'};
  position: ${props => props.position && props.position};
  font-size: ${props => props.fontSize && props.fontSize};
  height: ${props => props.height && props.height};
  color: ${props => (props.color ? props.color : props.theme?.colors?.white)};
  width: ${props => (props.width ? props.width : '')};
  visibility: ${props => props?.visibility || 'visible'};
  background: ${props =>
    props.bgColor ? props.bgColor : props.theme.colors?.magenta};
  display: flex;
  justify-content: center;
  align-items: center;
  &:focus {
    box-shadow: none;
    background: ${props => props.focusBgColor && props.focusBgColor};
  }
  &:hover {
    color: ${props => props.hoverColor && props.hoverColor};
    background: ${props => props.hoverBgColor && props.hoverBgColor};
    border: ${props => props.hoverborder && props.hoverborder};
    svg {
      transition-duration: 0.2s;
      ${(props) => props.hoverIconColor && `
        filter: ${props.hoverIconColor && props.hoverIconColor};
      `};
    }
    transition: all 0.3s ease-in;
    .arrow-icon {
      opacity: 1;
      transform: translateX(0);
      margin-left: 0.5rem;
      animation: ${moveIn} 0.5s ease;
    }
  }

  .arrow-icon {
    opacity: 0;
    transform: translateX(20px);
    // margin-left: 1.5rem;
    transition: all 0.3s ease-in-out;
  }


  //Font Family
  ${props => {
    const theme = props.theme;
    switch (props.family) {
    case 'primary':
      return `font-family: ${theme.fonts?.primary}`;
    case 'secondary':
      return `font-family: ${theme.fonts.secondary}`;
    case 'third':
      return `font-family: ${theme.fonts.third}`;
    case 'main':
      return `font-family: ${theme.fonts?.main}`;
    default:
      if (props.family) {
        return `font-family: ${props.family}`;
      } else {
        return `font-family: ${theme.fonts?.primary}`;
      }
    }
  }};

  // Font size
  ${props => {
    const theme = props.theme;
    switch (props.fontSize) {
    case 'heading': // 54px
      return `font-size: ${theme.texts.headingText}`;
    case 'subHeading': // 32
      return `font-size: ${theme.texts.subHeadingText}`;
    case 'smallText': // 14px
      return `font-size: ${theme.texts.smallText}`;
    case 'bigSubHeadingText':
      return `font-size: ${theme.texts.bigSubHeadingText}`;
    case 'subTitle':
      return `font-size: ${theme.texts.subTitle}`;
    case 'formLabel':
      return `font-size: ${theme.texts.formLabel}`;
    case 'normal':
      return `font-size: ${theme.texts.normalText}`;
    case 'font24px':
      return `font-size: ${theme.texts.font24px}`;
    case 'extraSmallText':
      return `font-size: ${theme.texts.extraSmallText}`;
    case 'font56px':
      return `font-size: ${theme.texts.font56px}`;
    case 'font28px':
      return `font-size: ${theme.texts.font28px}`;
    case 'font20px':
      return `font-size: ${theme.texts.font20px}`;
    case 'font18px':
      return `font-size: ${theme.texts.font18px}`;
    case 'font16px':
      return `font-size: ${theme.texts?.font16px}`;
    default:
      if (props.fontSize) {
        return `font-size: ${props.fontSize}`;
      } else {
        return `font-size: ${theme.texts?.font16px}`;
      }
    }
  }};

  ${props => props.animate ?
    css`
          animation: ${breatheAnimation} 1s ease-in-out infinite;
        ` :
    ''}
    &:disabled{
      cursor: not-allowed;
      opacity: 0.6;
    }
`;

export default Button;
// display: ${(props) => props.display && props.display};
// font-family: ${(props) => props.family && props.family};

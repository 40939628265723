import React from 'react';
import './banner.css';

const StickyBanner = () => {
  return (
    <div className="sticky-banner">
      <p className="sticky-banner-message">
      If you&apos;re experiencing login issues while accessing the Developer Portal,
       please refer to the guidelines provided for requesting login credentials.
      </p>
    </div>
  );
};

export default StickyBanner;

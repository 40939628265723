import {lazy} from 'react';
const Home = lazy(() => import('../components/homepage'));
const LandingDoc = lazy(() => import('../components/LandingDocument'));
const DocDetail = lazy(() => import('../components/DocumentDetails'));
const APIs = lazy(() => import('../components/APIs'));
const APIRefConfig = lazy(() => import('../components/APIRefConfig'));
const News = lazy(() => import('../components/NewsManagement/components/ExternalUserViews/NewsRoom'));
const NewsDetails = lazy(() => import('../components/NewsManagement/components/ExternalUserViews/NewsDetailsPage'));
const Contact = lazy(() => import('../components/contactUs'));
const CreateDocument = lazy(() => import('../components/createDocuments'));
const SDKdetail = lazy(() => import('../components/sdkDetail'));
const Profile = lazy(() => import('../components/profile'));
const AccessControl = lazy(()=>import('../components/accessControl'));
const Swagger = lazy(() => import('../components/Swagger'));
const TermsAndConditions = lazy(() => import('../components/footer/FooterLinks/terms'));
const DataUsePolicy = lazy(() => import('../components/footer/FooterLinks/dataUsePolicy'));
const AdminDashboard = lazy(() => import('../components/adminDashboard'));
const TPUAccountDetails = lazy(() => import('../components/tPUAccountDetails'));
const AccessManagementDashboard = lazy(() => import('../components/accessManagementDashboard'));
const ActivityLogReport = lazy(() => import('../components/ActivityLogReport'));
const WebsiteFeedback = lazy(() => import('../components/WebsiteFeedback'));
const Search = lazy(() => import('../components/Search'));
const PartnerAccountMgt = lazy(() => import('../components/PartnerAccountMgt'));
const NewsManagement = lazy(() => import('../components/NewsManagement'));
const CreatePartnerAccount = lazy(() => import('../components/CreatePartnerAccount'));
const PartnerDetail = lazy(() => import('../components/PartnerDetail'));
const GraphQLDocument = lazy(() => import('../components/GraphQLDocument'));
const CreateNews = lazy(() => import('../components/NewsManagement/components/CreateNews'));
const Routes = [
  {
    path: '/',
    component: Home,
    exact: true,
  },
  {
    path: '/terms-of-use',
    name: 'Terms And Conditions',
    component: TermsAndConditions,
    exact: true
  },
  {
    path: '/data-use-policy',
    name: 'Terms And Conditions',
    component: DataUsePolicy,
    exact: true
  },
  {
    path: '/documents/',
    component: LandingDoc,
    exact: true,
    secure: true,
  },
  {
    path: '/contactUs/',
    component: Contact,
    exact: true,
    secure: true,
  },
  {
    path: '/documents/create',
    component: CreateDocument,
    exact: true,
    secure: true,
  },
  {
    path: '/documents/edit/:id',
    component: CreateDocument,
    exact: true,
    secure: true,
  },
  {
    path: '/sdk',
    component: LandingDoc,
    exact: true,
    secure: true,
  },
  {
    path: '/sdk/create',
    component: CreateDocument,
    exact: true,
    secure: true,
  },
  {
    path: '/sdk/edit/:id',
    component: CreateDocument,
    exact: true,
    secure: true,
  },
  {
    path: '/sdk/:id',
    component: SDKdetail,
    exact: true,
    secure: true,
  },
  {
    path: '/graphql-document/:env',
    component: GraphQLDocument,
    exact: true,
    secure: true,
  },
  {
    path: '/graphql-document/:env/:name',
    component: GraphQLDocument,
    exact: true,
    secure: true,
  },
  {
    path: '/news/:id',
    component: NewsDetails,
    exact: true,
    secure: true,
  },
  // {
  //   path: '/domain/',
  //   component: LandingDoc,
  //   exact: true,
  //   secure: true,
  // },
  // {
  //   path: '/domain/create',
  //   component: CreateDocument,
  //   exact: true,
  //   // secure: true,
  // },
  // {
  //   path: '/domain/edit/:id',
  //   component: CreateDocument,
  //   exact: true,
  //   // secure: true,
  // },
  // {
  //   path: '/domain/:key',
  //   component: DocDetail,
  //   exact: true,
  //   secure: true,
  // },
  {
    path: '/documents/:key',
    component: DocDetail,
    exact: true,
    secure: true,
  },
  {
    path: '/documents/wiki/:key',
    component: DocDetail,
    exact: true,
    secure: true,
  },
  {
    path: '/documents/wiki/:key/:id',
    component: DocDetail,
    exact: true,
    secure: true,
  },
  {
    path: '/help',
    component: DocDetail,
    exact: true,
    secure: true,
  },
  {
    path: '/help/create',
    component: DocDetail,
    exact: true,
    secure: true,
  },
  {
    path: '/reference/:env',
    component: APIs,
    exact: true,
    secure: true,
  },
  {
    path: '/reference/:env/:name',
    component: Swagger,
    exact: true,
    secure: true,
  },
  {
    path: '/api-reference-config',
    component: APIRefConfig,
    secure: true,
    exact: true
  },
  // {
  //   path: '/reference/swagger',
  //   component: APISwagger,
  //   exact: true,
  //   secure: true,
  // },
  {
    path: '/news',
    component: News,
    exact: true,
    secure: true
  },
  // {
  //   path: '/auth',
  //   component: SignIn,
  //   exact: true,
  // },
  {
    path: '/profile/',
    component: Profile,
    exact: true,
    secure: true
  },
  {
    path: '/activity_log_report',
    component: ActivityLogReport,
    exact: true,
    secure: true
  },
  {
    path: '/website_feedback',
    component: WebsiteFeedback,
    exact: true,
    secure: true
  },
  {
    path: '/access_control/',
    component: AccessControl,
    exact: true,
    secure: true,
  },
  {
    path: '/partner_account_mgt/',
    component: PartnerAccountMgt,
    exact: true,
    secure: true,
  },
  {
    path: '/news_management/',
    component: NewsManagement,
    exact: true,
    secure: true,
  },
  {
    path: '/create_partner_account/',
    component: CreatePartnerAccount,
    exact: true,
    secure: true,
  },
  {
    path: '/partner_detail/:id',
    component: PartnerDetail,
    exact: true,
    secure: true,
  },
  {
    path: '/partner_detail/:id/:tab',
    component: PartnerDetail,
    exact: true,
    secure: true,
  },
  {
    path: '/adminDashboard/',
    component: AdminDashboard,
    exact: true,
    secure: true
  },
  {
    path: '/tpu_account_details/',
    component: TPUAccountDetails,
    exact: true,
    secure: true
  },
  {
    path: '/access_management_dashboard/',
    component: AccessManagementDashboard,
    exact: true,
    secure: true
  },
  {
    path: '/search-results/:val',
    component: Search,
    exact: true,
    secure: true
  },
  {
    path: '/search-results/',
    component: Search,
    exact: true,
    secure: true
  },
  {
    path: '/news_management/create_news/',
    component: CreateNews,
    exact: true,
    secure: true
  },
  {
    path: '/news_management/edit/:id',
    component: CreateNews,
    exact: true,
    secure: true,
  },
];

export default Routes;

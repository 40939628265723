import React, {useEffect, useState, useRef} from 'react';
import {connect} from 'react-redux';
import {AlertConfig} from '../../actions/alertConfigAction';
import {Alert, AlertTitle} from '@material-ui/lab';
import {makeStyles, Snackbar} from '@material-ui/core';
import styled from 'styled-components';
import SuccessIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/CancelOutlined';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';
import InnerHTML from 'dangerously-set-html-content';

const borderColors = {
  success: '#4CAF50',
  error: '#e73131',
  warning: '#FE9A3A',
};

const StyledAlert = styled(Alert)`
  min-width: 20rem;
  max-width: 30rem;
  // min-height: 5rem;
  // max-height: 8rem; 
  border-left: 1rem solid ${({severity}) => borderColors[severity]};
  align-items: center;
  background-color: #FFFFFF !important;
  padding: 1rem !important;
  box-shadow: 0.125rem 0.5rem 1.5625rem 0 rgba(144, 157, 193, 0.4) !important;

  .MuiAlert-message {
    font-family: ${({theme}) => theme.fonts.primary};
    font-size: ${({theme}) => theme.texts.font16px};
    font-weight: bold;
    color: ${({theme}) => theme.colors.fifth};
    text-transform: capitalize;
  }
  .MuiTypography-body1 {
    font-family: ${({theme}) => theme.fonts.primary};
    font-weight: 500;
  }
  & > .MuiAlert-action button:focus {
    outline: none;
  }
`;

const StyledAlertTitle = styled(AlertTitle)`
  // max-height: 3.6rem;
  max-width: 100%;
  overflow: hidden;
  font-size: ${({theme}) => theme.texts.font16px} !important;
  color: ${({theme}) => theme.colors.fifth};
  text-transform: none;
  margin: 0 !important;
  // text-overflow: ellipsis;
  // -webkit-line-clamp: 3;
  // display: -webkit-box;
  // -webkit-box-orient: vertical;
  word-break: break-word;
  &.heading {
    font-weight: bold;
    line-height: 1.8;
  }
`;

const Success = styled(SuccessIcon)`
  color: #4CAF50 !important;
`;
const Error = styled(ErrorIcon)`
  color: #e73131 !important;
`;
const Warning = styled(WarningIcon)`
  color: #FE9A3A !important;
`;

const useStyles = makeStyles((theme) => ({
  snackbar: {
    zIndex: 1003,
  },
}));

const AlertToast = (props) => {
  const classes = useStyles();

  const init = {open: false, message: ''};
  const {change_alert, alertConfig} = props;
  const alertRef = useRef(null);
  // console.log('alert', alertConfig);

  const [alertState, setAlertState] = useState(init);

  useEffect(() => {
    function handleClickOutside(event) {
      if (alertRef.current && !alertRef.current.contains(event.target)) {
        handleClose();
      }
    }
    
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [alertRef]);
  

  const checkBackButton = () => {
    if (alertConfig) {
      // history.pushState(null, null, location.href);
      window.onpopstate = function() {
      // history.go(1);
        handleClose();
      };
    }
  };

  const handleOpen = (alertObj) => {
    setAlertState({open: true, ...alertObj});
  };

  const handleClose = () => {
    change_alert({type: 'ALERT_TOAST', data: {}});
    setAlertState(init);
  };

  useEffect(() => {
    if (alertConfig) {
      handleOpen(alertConfig);
      checkBackButton();
    } else {
      handleClose();
    }
  }, [alertConfig]);

  if ( alertConfig && alertConfig.message) {
    return (
      <Snackbar
        className={classes.snackbar}
        role='alert'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={alertState.open}
        onClose={handleClose}
        transitionDuration={{
          enter: 400,
          exit: 30,
        }}
        ref={alertRef}
        autoHideDuration={10000}
      >
        <StyledAlert
          elevation={3}
          severity={alertState.severity}
          iconMapping={{
            success: <Success />,
            error: <Error />,
            warning: <Warning />,
          }}
          onClose={handleClose}
        >
          {alertState.severity !== 'success' && alertState.severity}
          <StyledAlertTitle className="heading">
            {alertState.head?alertState.head: '' }
          </StyledAlertTitle>
          <StyledAlertTitle className="alertTitle">
            <div>{alertState.message}</div>
            {/* <InnerHTML html={(alertState && alertState.message)? alertState.message: '<div></div>'}/> */}
          </StyledAlertTitle>
        </StyledAlert>
      </Snackbar>
    );
  } else {
    return <></>;
  }
};

const mapStateToProps = ({alertConfig}) => ({alertConfig});

const mapDispatchToProps = dispatch => ({
  change_alert: (data) => dispatch(AlertConfig(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AlertToast);
